import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import SignUp from "../sections/signupParticipant"
import ZapButton from "../components/ZapButton"

function CodeInvite({ location }) {
  return (
    <Layout>
      <SEO title="Cadastro" />
      <SignUp deal={location.state?.deal} cpf={location.state?.cpf}/>
      <ZapButton />
    </Layout>
  );
}

export default CodeInvite;