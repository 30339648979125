import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

export const initialValues = {
  phone: '',
  name: '',
  cpf: '',
  password: '',
  passwordConfirm: '',
};

export const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .typeError('Valor inválido')
    .min(11, 'O campo telefone deve estar completo')
    .required('O campo telefone é obrigatório'),
  name: Yup.string()
    .min(5, 'Digite o nome completo')
    .required('O nome é obrigatório'),
  cpf: Yup.string()
    .test('CPF Test', 'Informe um cpf válido', (value) => cpf.isValid(value))
    .required('O campo cpf é obrigatório'),
  password: Yup.string()
    .min(6, 'Crie um senha com pelo menos 6 caracteres.')
    .required('A senha é obrigatório'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senha incompatível')
    .required('A senha é obrigatório'),
});
