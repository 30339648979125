import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"
import client from "../../service/graphql"
import mutations from "../../service/mutations"
import { useFormik } from 'formik';
import { validationSchema, initialValues } from './formik';

import {
  Container,
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  Loading,
  ContainerError,
  ErrorText,
  HelperText
} from "./styles"
import { Body, Buttons } from "../../steps/shared-styles"
import HeaderProgress from "../../components/HeaderProgress"
import { formatNumber, formatAndExtractNumber } from "../../utils"

const CodeInvite = ({ deal, cpf }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  async function onSubmit(values) {
    const { name, cpf, password, phone } = values;
    const { phone_number } = formatAndExtractNumber(phone);

    setLoading(true);

    try {
      const { createAccountPassword } = await client.request(mutations.CREATE_ACCOUNT_PASSWORD, {
        input: {
          name,
          document: {
            document_type: 'CPF',
            document_number: cpf,
          },
          phone_number,
          origin: 'LP - Convidado',
          password,
          is_mobile: false,
        },
      });

      const { token } = createAccountPassword;
      localStorage.setItem('@verizaLP:token', token);

      navigate('/finish-invite', { state: { deal } })
    }catch({response}){
      setError(response.errors[0]
        ? response.errors[0].message
        : "ocorreu um erro inesperado")
    } finally{
      setLoading(false)
    }
  }

  const formik = useFormik({
    onSubmit,
    initialValues: {
      ...initialValues,
      cpf: cpf ? cpf : '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  return (
    <div>
      <HeaderProgress description="Sobre você" percent={50} />
      <Container>
        <Section>
          <Body>
            <Header>
              <Profile>
                <User src={Photo} />
              </Profile>
              <Label><p>Entre em um grupo:</p></Label>
            </Header>
            <Form>
              <Input
                placeholder="Digite o seu nome"
                onChange={({ target }) => formik.setFieldValue('name', target.value)}
                value={formik.values.name}
                error={formik.errors.name}
              />
              {formik.errors.name && <HelperText>{formik.errors.name}</HelperText>}

              <Input
                placeholder="(DD) 99999-9999"
                onChange={({ target }) => formik.setFieldValue('phone', target.value)}
                mask="(99) 99999-9999"
                maskChar=""
                type="tel"
                value={formik.values.phone}
                error={formik.errors.phone}
              />
              {formik.errors.phone && <HelperText>{formik.errors.phone}</HelperText>}

              <Input
                placeholder="***.***.***-**"
                onChange={({ target }) => {}}
                mask="999.999.999-99"
                maskChar=""
                value={formik.values.cpf}
                error={formik.errors.cpf}
              />
              {formik.errors.cpf && <HelperText>{formik.errors.cpf}</HelperText>}

              <Input
                placeholder="Crie uma senha"
                onChange={({ target }) => formik.setFieldValue('password', target.value)}
                value={formik.values.password}
                type='password'
                error={formik.errors.password}
              />
              {formik.errors.password && <HelperText>{formik.errors.password}</HelperText>}

              <Input
                placeholder="Confime sua senha"
                onChange={({ target }) => formik.setFieldValue('passwordConfirm', target.value)}
                value={formik.values.passwordConfirm}
                type='password'
                error={formik.errors.passwordConfirm}
              />
              {formik.errors.passwordConfirm && <HelperText>{formik.errors.passwordConfirm}</HelperText>}

              {error.length > 0 && (
                <ContainerError>
                  <ErrorText>{error}</ErrorText>
                </ContainerError>
              )}

              {loading && <Loading />}
            </Form>
            <Buttons style={{ marginTop: 10 }}>
              <Button onClick={formik.submitForm}>
                Próximo
              </Button>
            </Buttons>
          </Body>
        </Section>
      </Container>
    </div>
  )
}

export default CodeInvite
